<article
	class={'max-p prose-sm mx-auto w-full py-6 md:prose-xl' +
		' ' +
		// headings
		'prose-headings:!font-bold prose-headings:!text-white prose-h1:!text-heading7' +
		' ' +
		// h2
		'prose-3:!font-semibold prose-h2:!text-heading3' +
		' ' +
		// h3
		'prose-3:!font-semibold prose-h3:!text-heading3' +
		' ' +
		// lead
		'prose-lead:text-white dark:prose-lead:text-white' +
		' ' +
		// p
		'prose-p:text-caption3 prose-p:font-medium prose-p:leading-normal prose-p:text-white prose-p:opacity-70' +
		' ' +
		// lists
		'prose-strong:!font-bold prose-strong:!text-white prose-ol:list-decimal prose-ul:list-outside prose-ul:list-disc prose-li:!ml-8 prose-li:text-caption3 prose-li:!text-white prose-li:!opacity-70' +
		' ' +
		// links
		'prose-a:font-semibold dark:prose-a:text-white' +
		' ' +
		// tables
		'prose-table:mx-auto prose-table:rounded-lg prose-table:border-y-[0.5px] prose-table:border-placeholder prose-table:text-white' +
		' ' +
		// table body
		'prose-table:text-caption7 prose-thead:border-b prose-thead:border-placeholder prose-tr:divide-x-[0.5px] prose-tr:divide-placeholder prose-tr:border-b-[0.5px] prose-tr:border-placeholder prose-th:p-4 prose-td:p-4'}>
	<slot />
</article>
